import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Link } from 'gatsby';

export const Logo = styled(Link)`
  ${tw`flex items-center mr-auto`};
`;


export const Image = styled.figure`
  ${tw`w-16 h-16 mr-3 rounded-full`};

  img {
    ${tw`rounded-full w-full h-full`};
  }
`;
