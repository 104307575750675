import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Styled from './styles';
import LogoImage from '../../../assets/images/logo.svg';

const Logo = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const logoTitle = site.siteMetadata.title;

  return (
    <Styled.Logo to="/">
      <Styled.Image>
        <img src={LogoImage} alt={logoTitle}/>
      </Styled.Image>
    </Styled.Logo>
  );
};

export default Logo;
